import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { FormattedMessage } from 'react-intl'
import { Box, Heading, Text } from 'rebass/styled-components'
import { parse, compareAsc } from 'date-fns'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Section, {
  SectionWithPattern,
  SectionWithObjects,
  SectionWithObjectsGold,
} from '../components/section'
import Container from '../components/container'
import Hero from '../components/hero'
import Carousel from '../components/carousel'
import History from '../components/history'
import BreweryScheme from '../components/brewery-scheme'
import NextStep from '../components/next-step'
import brushDoodles from '../images/brush-doodles.png'
import headLogo from '../images/head-logo@2x.png'
import cup from '../images/cup.svg'
import getTranslations from '../utils/getTranslations'
import VideoWrapper from '../components/video-wrapper'
import getLocale from '../utils/getLocale'
import Iframe from '../components/iframe'

const AboutPage = ({ data, pageContext }) => {
  const { locale } = pageContext
  const { page, heroBackgroundImage, nextStepBackground } = data
  const { title, content, acf, polylang_translations: translations } = page
  const {
    text_kam_dal: textKamDal,
    popisek_tlacitka_kam_dal: popisekTlacitkaKamDal,
    odkaz_kam_dal: odkazKamDal,
    popis_v_hero: popisVHero,
    popis_procesu: popisProcesu,
    poloha_pivovaru: polohaPivovaru,
    videos,
    about_us_gallery: aboutUsGallery,
    iframe_kod: iframeKod,
    datum_zobrazeni_iframu: datumZobrazeniIframu,
  } = acf
  let shouldPublishIframe = false

  if (iframeKod && datumZobrazeniIframu) {
    const publicationDate = parse(datumZobrazeniIframu, 'yyyy-MM-dd HH:mm:ss', new Date())
    shouldPublishIframe = compareAsc(new Date(), publicationDate) === 1
  }

  return (
    <Layout
      locale={locale}
      pageTranslationLinks={getTranslations(locale, translations)}
    >
      <SEO title={title} />

      <Hero
        title={title}
        html={popisVHero || null}
        backgroundImage={heroBackgroundImage}
        backgroundPosition="50% 30%"
      />

      {shouldPublishIframe && <Iframe url={iframeKod} />}

      <SectionWithPattern pt={5} backgroundImage={brushDoodles}>
        <Container>
          <Heading as="h2" variant="sectionTitle" style={{ color: '#F6D029' }}>
            <FormattedMessage id="about.story" defaultMessage="Příběh" />
          </Heading>

          <Text
            variant="body"
            dangerouslySetInnerHTML={{ __html: content }}
            sx={{
              p: {
                marginBottom: '1.5rem',
              },
            }}
          />
        </Container>
      </SectionWithPattern>

      <Section pt={5}>
        <Container>
          <Heading as="h2" variant="sectionTitle">
            <FormattedMessage id="about.brewery" defaultMessage="Pivovar" />
          </Heading>

          <Text
            variant="body"
            dangerouslySetInnerHTML={{ __html: polohaPivovaru }}
          />
        </Container>

        <BreweryScheme locale={getLocale(locale)} />

        <Container>
          <Text
            variant="body"
            sx={{
              '& p': {
                mb: 3,
              },
            }}
            dangerouslySetInnerHTML={{ __html: popisProcesu }}
          />
        </Container>

        <Box sx={{ mb: 5 }}>
          <Container px={[0]}>
            <Box
              sx={{
                p: [0, 2, 2],
                borderRadius: '4px',
                boxShadow: '0 8px 24px rgba(0, 0, 0, 0.2)',
              }}
            >
              <Carousel images={aboutUsGallery} />
            </Box>
          </Container>
        </Box>
      </Section>

      {videos && (
        <SectionWithObjectsGold>
          <Container>
            <Heading variant="sectionTitle" color="darkGray">
              <FormattedMessage id="about.videos" defaultMessage="Videa" />
            </Heading>

            <Box
              sx={{
                maxWidth: ['100%', '40rem'],
                mx: 'auto',
              }}
            >
              {videos.map((video) => (
                <Box key={video.url} mb="4">
                  <VideoWrapper video={video.url} />
                </Box>
              ))}
            </Box>
          </Container>
        </SectionWithObjectsGold>
      )}

      {acf.oceneni && (
        <SectionWithObjects pt={5}>
          <Container>
            <Heading as="h2" variant="sectionTitle">
              <FormattedMessage id="about.awards" defaultMessage="Ocenění" />
            </Heading>

            <History history={acf.oceneni} topIcon={cup} />
          </Container>
        </SectionWithObjects>
      )}

      {acf.historie && (
        <SectionWithObjects pt={5}>
          <Container>
            <Heading as="h2" variant="sectionTitle">
              <FormattedMessage id="about.history" defaultMessage="Historie" />
            </Heading>

            {acf.historie && (
              <History history={acf.historie} topIcon={headLogo} />
            )}
          </Container>
        </SectionWithObjects>
      )}

      <NextStep
        title={textKamDal || ''}
        link={odkazKamDal}
        label={popisekTlacitkaKamDal}
        backgroundImage={nextStepBackground}
      />
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default AboutPage

export const pageQuery = graphql`
  query AboutPageQuery($key: String!) {
    page: wordpressPage(slug: { eq: $key }) {
      ...PageData
      ...PageTranslations
      acf {
        ...NextStep
        about_us_gallery {
          localFile {
            childImageSharp {
              fluid(quality: 80, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        popis_v_hero
        popis_procesu
        poloha_pivovaru
        iframe_kod
        datum_zobrazeni_iframu
        videos {
          url
        }
        oceneni {
          rok {
            cislo_roku
            polozky {
              text
              featured_media {
                localFile {
                  childImageSharp {
                    fluid(quality: 80, maxWidth: 960) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
          }
        }
        historie {
          rok {
            cislo_roku
            vystav
            polozky {
              datum
              text
              featured_media {
                localFile {
                  childImageSharp {
                    fluid(quality: 80, maxWidth: 960) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    heroBackgroundImage: file(relativePath: { eq: "tym-opice.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    nextStepBackground: file(relativePath: { eq: "pivo-v-ruce.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
