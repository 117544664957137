import React from 'react'
import PropTypes from 'prop-types'

const Iframe = ({ url, height = 640 }) => {
  return (
    <iframe
      src={url}
      width="100%"
      height={height || 640}
      seamless="seamless"
      scrolling="no"
      frameBorder="0"
      allowFullScreen
      title="iframe"
    />
  )
}

Iframe.propTypes = {
  url: PropTypes.string.isRequired,
  height: PropTypes.number,
}

export default Iframe
