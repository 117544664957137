import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { Box, Flex, Heading, Text } from 'rebass/styled-components'

const HistoryWrapper = styled(Flex)`
  flex-direction: column;
  position: relative;
  padding-top: 3rem;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 3px;
    height: 100%;
    background-color: #242424;
  }

  &:after {
    content: '';
    position: absolute;
    width: 3px;
    height: 10rem;
    bottom: 0;
    left: 0;
    background: linear-gradient(to top, #333333, #242424);
  }
`

const StyledYear = styled(Flex)`
  &:before,
  &:after {
    position: absolute;
    content: '';
    background-color: ${(props) => props.theme.colors.gold};
  }

  &:before {
    top: 1.5rem;
    left: 0;
    width: 1.75rem;
    height: 3px;
    background-color: #fff;

    @media screen and (min-width: ${(props) => props.theme.breakpoints[0]}) {
      width: 3rem;
    }
  }

  &:after {
    width: 1rem;
    height: 1rem;
    top: 1.5rem;
    left: 0;
    border-radius: 50%;
    transform: translate(-0.45rem, -0.45rem);
  }
`

const TopSymbol = ({ symbolIcon, ...props }) => (
  <Box
    sx={{
      position: 'relative',
      top: ['calc(-136px / 2)'],
      left: ['-0.5rem', 'calc(-136px / 4)'],
      width: ['calc(136px / 2)'],
      height: ['calc(136px / 2)'],
      marginBottom: ['calc(-136px / 4)'],
      background: `transparent url(${symbolIcon}) no-repeat center center`,
      backgroundSize: '100%',
    }}
    {...props}
  />
)

const BeerAmount = styled.p`
  margin: 0;
  padding: 0.25rem 0.5rem;
  background-color: ${(props) => props.theme.colors.gold};
  border-radius: 3px;
  color: ${(props) => props.theme.colors.darkGray};
`

const StyledImg = styled(Img)`
  position: relative;
  margin-top: 1rem;
  border-radius: 6px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25);
  filter: sepia(1);
  transition: all 200ms ease-in-out;

  &:focus,
  &:hover {
    filter: sepia(0);
  }
`

const History = ({ history, topIcon }) => {
  return (
    <HistoryWrapper>
      {topIcon && <TopSymbol symbolIcon={topIcon} />}

      {history.map((rok) => {
        const { vystav } = rok.rok

        return (
          <StyledYear
            key={rok.rok.cislo_roku}
            sx={{
              flexDirection: 'column',
              alignItems: 'flex-start',
              position: 'relative',
              mb: 4,
              pl: [4, 5],
            }}
          >
            <Flex sx={{ alignItems: 'center', mb: 4 }}>
              <Heading as="h3" variant="sectionTitle" sx={{ mr: 3, mb: 0 }}>
                {rok.rok.cislo_roku}
              </Heading>

              {vystav && (
                <BeerAmount>
                  <FormattedMessage
                    id="about.beerVolume"
                    defaultMessage="Výstav: <strong>{volume}</strong>"
                    values={{
                      strong: (...chunks) => <strong>{chunks}</strong>,
                      volume: vystav,
                    }}
                  />
                </BeerAmount>
              )}
            </Flex>

            <Flex
              sx={{
                width: ['100%'],
                flexDirection: rok.rok.polozky.length > 1 ? 'row' : 'column',
                alignItems: 'stretch',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
              }}
            >
              {rok.rok.polozky.map((polozka) => {
                const { featured_media: featuredMedia } = polozka

                return (
                  <Flex
                    key={polozka.text}
                    sx={{
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      mb: 4,
                      p: 3,
                      bg: 'gold',
                      borderRadius: '8px',
                      fontSize: 7,
                      width:
                        rok.rok.polozky.length > 0 ? ['100%', '49%'] : '100%',
                    }}
                  >
                    <Text sx={{ lineHeight: 1.5 }}>
                      {`${polozka.datum ? `${polozka.datum}: ` : ''} ${
                        polozka.text
                      }`}
                    </Text>

                    {featuredMedia && featuredMedia.localFile && (
                      <Box>
                        <StyledImg
                          fluid={featuredMedia.localFile.childImageSharp.fluid}
                          alt={polozka.text}
                        />
                      </Box>
                    )}
                  </Flex>
                )
              })}
            </Flex>
          </StyledYear>
        )
      })}
    </HistoryWrapper>
  )
}

History.propTypes = {
  history: PropTypes.array.isRequired,
  topIcon: PropTypes.string,
}

TopSymbol.propTypes = {
  symbolIcon: PropTypes.string,
}

export default History
