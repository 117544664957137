import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box } from 'rebass/styled-components'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'

import ProcesEN from '../images/inline/process.en.svg'
import ProcesCS from '../images/inline/process.cs.svg'
import isBrowser from '../utils/isBrowser'

const StyledTooltip = styled(ReactTooltip)`
  max-width: 12rem;
  padding: 1rem;
  line-height: 1.75;
  white-space: normal;
`

const tooltipTexts = {
  cs: {
    'id-slad':
      'Slad je namočené, vyklíčené a vysušené obilí. Pro pivo se používá ječmen, v malé míře i pšenice, oves, žito. Základem bývá světlý plzeňský slad, existuje ale celá řada další sladů, které se liší barvou a chutí (zrno se suší při vyšších teplotách). V pivovaru se slad namele ve válcovém štorovníku a dopravníkem se dopraví do varny.',
    'id-rmutovani':
      'Při rmutování dochází ke smíchání sladu a vodu. Působením enzymů ze sladu, přihříváním a povařováním této směsi se ze sladu uvolňují do vody sacharidy, které pak slouží jako potrava pro kvasnice. Množství a složení sacharidů pak určuje sílu a plnost piva.',
    'id-voda':
      'Pro výrobu piva je potřeba pitná, nezávadná voda. U vody se sleduje obsah dusičnanů, železa, manganu, dalších iontů a solí. Pro výrobu českých ležáků je vhodná měkká voda, pro svrchně kvašené piva pak spíše voda tvrdší. Vodu pro vaření piva lze před procesem upravit podle potřeb a požadavků daného pivního stylu.',
    'id-rmutovani-chmelovar':
      'Po rmutování je třeba oddělit pevný podíl zbytků sladu od kapaliny. K tomu slouží druhá nádoba varny, scezovací káď. Její dno je tvořeno z nerezového síta, které zachytí pevné zbytky sladu, tzv. mláto. Protékající kapalina obsahuje vyluhované sacharidy, vrací se zpět do kotle a nazývá se sladina.',
    'id-chmelovar':
      'Při chmelovaru se ke sladině přidává chmel. Chmel je třeba povařit, aby se z něj dostaly potřebné látky. Přidává se na varně v několika fázích ve forně šišek, pelet, extraktu. Nejčastěji se používají chmelové pelety, což je mechanicky upravený a slisovaný chmel.',
    'id-chmel':
      'Chmel dodává pivu hořkost (hořké kyseliny) a aroma (chmelové silice). Existuje celá řada chmelů, v česku je znám žatecký poloranný červeňák, který je příjemně kořenitý a používá se pro spodně kvašené piva. Ze zahraničí jsou velice oblíbené chmely americké, které rostou v jiných klimatických podmínkách a díky tomu jsou velice aromatické, až ovocné, citrusové atd. Používají se převážně pro svrchně kvašené piva typu ALE [ejl].',
    'id-chlazenimladiny':
      'Po chmelovaru máme pohromadě mladinu, což je sladká a hořká voda, která obsahuje sacharidy ze sladu, hořké a aromatické látky z chmele. Obsah sacharidů v tomto bodě pak určuje stupňovitost piva. Mladinu je potřeba ochladit a provzdušnit tak, abychom do ní mohli přidat kvasnice pro proces kvašení. Pro spodně kvašená piva se mladina chladí na cca 10°C, pro svrchně pak na cca 20°C.',
    'id-kvasnice':
      'Kvasnice jsou vlastně malá zvířátka v tekuté formě, o které je potřeba se starat. Kvasnice je potřeba udržovat v čistotě a chladu. Kvasnice si sedají do kónusu nádoby po hlavním kvašení a odebírají se pro další použití. Při dodržováním správné hygieny je možné několikrát takto otočit a použít. Nové kvasnice si pak můžete pořídit v sušené nebo tekuté formě od specializovaných výrobců nebo i od větších pivovarů.',
    'id-kvaseniazrani':
      'Kvašení probíhá v kvasných tancích při řízené teplotě, spodní kolem 10°C, svrchní kolem 20°C. při kvašení kvasnice zpracovávají cukr a vytvářejí z něj alkohol a oxid uhličitý. Po několika dnech je prokvašena většina cukrů, tank se uzavře, mladé pivo se nechá zachladit až na 1°C a probíhá zrání a přirozené sycení piva oxidem uhličitým.',
    'id-staceni':
      'Před stáčením se pivo přepustí do přetlačných tanků. Dosahuje se tím lepší kvality piva s ohledem na jeho zákal a standardnost. Pivo se nefiltruje ani nepasteruje a zůstává tak ve své živé podobě. Při stáčení se musí dbát na to, aby se pivo nedostalo do styku s kyslíkem. Proto se používá inertní plyn (dusík, oxid uhličitý nebo jejich směs) pro tlačení piva, předplňování tanků, lahví, sudů.',
  },
  en: {
    'id-slad':
      'Malt is soaked, germinated and dried grain. For beer barley is used but to a small extent also wheat, oats, rye can be used. The basis is light Pilsen malt but there are a number of other malts that differ in color and taste (grain is dried at higher temperatures). At the brewery, the malt is ground in a cylindrical pile and transported to the Mash tun by a conveyor.',
    'id-rmutovani':
      'When mashing, malt and water are mixed. By the action of enzymes from malt, reheating and boiling of this mixture, carbohydrates are released from malt, which then serve as food for yeast. The amount and composition of carbohydrates then determines the strength and fullness of the beer.',
    'id-voda':
      'Drinking, safe water is required for beer production. Water is monitored for nitrate, iron, manganese, other ions and salts. Soft water is suitable for the production of Czech lagers, and harder water for top-fermented beers. Water for brewing beer can be pre-treated according to the needs and requirements of the beer style.',
    'id-rmutovani-chmelovar':
      'After mashing, the solid portion of the malt residue must be separated from the liquid. A second brewing vessel, a lauter tun, serves for this purpose. Its bottom is made of stainless steel sieve, which catches solid remnants of malt, so-called grains. The flowing liquid contains leached carbohydrates, is returned to the boiler and is called wort.',
    'id-chmelovar':
      'Hops are added to the wort during the hopping process. The hops need to be cooked to get the necessary substances out of it. It is added to the brewhouse in several stages in the form of cones, pellets, extract. Hop pellets are the most commonly used, which is mechanically treated and pressed hops.',
    'id-chmel':
      'Hops give the beer bitterness (bitter acids) and aroma (hop essential oils). There are a number of hops, in the Czech Republic is known Saaz semi-orange red, which is pleasantly spicy and is used for bottom-fermented beers. From abroad are very popular American hops, which grow in other climatic conditions and therefore are very aromatic, even fruity, citrus, etc. They are used mainly for top-fermented ALE type beers.',
    'id-chlazenimladiny':
      'We have wort together after the boiling, which is fresh and bitter water, which contains carbohydrates from malt, bitter and aromatic substances from hops. The carbohydrate content at this point then determines the degree of beer. The wort needs to be cooled and aerated so that yeast can be added to it for the fermentation process. For bottom-fermented beers, the wort is cooled to about 10 °C, for top-fermented beers to about 20 °C.',
    'id-kvasnice':
      'Yeasts are actually small animals in liquid form that need to be cared for. Yeast should be kept clean and cool. The yeast sits in the cone of the container after the main fermentation and is harvested for further use. With proper hygiene, it is possible to turn and use it several times. New yeast can be purchased in dried or liquid form from specialized manufacturers or even from larger breweries.',
    'id-kvaseniazrani':
      'Fermentation takes place in fermentation tanks at controlled temperature, bottom around 10 °C, top around 20 °C. when fermented, yeast processes sugar to make it alcohol and carbon dioxide. After a few days most of the sugars are fermented, the tank is closed, the young beer is allowed to cool down to 1 °C and the beer is matured and naturally carbonated.',
    'id-staceni':
      'Before bottling, the beer is released into transfer tanks. This results in better beer quality with regard to its haze and standard. Beer is neither filtered nor pasteurized and thus remains in its living form. When bottling it must be ensured that the beer does not come into contact with oxygen. Therefore, an inert gas (nitrogen, carbon dioxide or a mixture thereof) is used to push beer, pre-fill tanks, bottles, barrels.',
  },
}

const getTooltipText = (locale, elementId) => {
  return tooltipTexts[locale][elementId]
}

const elementIdLowerCase = (elementId) => {
  return elementId.toLowerCase()
}

const setTooltip = (element, text) => {
  if (typeof text !== 'undefined') {
    element.setAttribute('data-tip', text)
  }
}

const processStyle = {
  maxWidth: 'none',
  width: '1280px',
  height: 'auto',
  '& g': {
    pointerEvents: 'bounding-box',
  },
}

const BreweryScheme = ({ locale }) => {
  useEffect(() => {
    if (isBrowser) {
      const elements = document.querySelectorAll('#process g[id]')

      elements.forEach((element) => {
        if (element.id !== null) {
          const elementId = elementIdLowerCase(element.id)
          const text = getTooltipText(locale, elementId)

          setTooltip(element, text)
        }
      })

      ReactTooltip.rebuild()
    }
  }, [])

  return (
    <Box
      sx={{
        justifyContent: 'center',
        overflow: 'scroll',
      }}
    >
      <Box
        sx={{
          justifyContent: 'center',
          overflow: 'auto',
          width: '1280px',
          m: 'auto',
        }}
      >
        {locale === 'cs' && <ProcesCS id="process" style={processStyle} />}

        {locale === 'en' && <ProcesEN id="process" style={processStyle} />}

        <StyledTooltip multiline place="right" />
      </Box>
    </Box>
  )
}

BreweryScheme.propTypes = {
  locale: PropTypes.string.isRequired,
}

export default BreweryScheme
