import React from 'react'
import PropTypes from 'prop-types'
import Image from 'gatsby-image'
import { FormattedMessage } from 'react-intl'
import styled, { css } from 'styled-components'
import { Box } from 'rebass/styled-components'
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'

import CarouselArrow from '../images/carousel-arrow.png'

const StyledButtonsCSS = css`
  position: absolute;
  width: 3rem;
  height: 3rem;
  top: calc(50% - 1.5rem);
  background: none;
  border: none;
  outline: none;
  background: transparent url(${CarouselArrow}) center center no-repeat;
  background-size: 100%;
  transition: all 100ms ease-in-out;
  text-indent: -10000px;
`

const StyledButtonBack = styled(ButtonBack)`
  ${StyledButtonsCSS}
  left: 1rem;

  &:hover {
    left: 0.5rem;
  }
`

const StyledButtonNext = styled(ButtonNext)`
  ${StyledButtonsCSS}
  right: 1rem;
  transform: rotate(180deg);

  &:hover {
    right: 0.5rem;
  }
`

const Carousel = ({ images }) => {
  if (!images) {
    return null
  }

  return (
    <Box
      sx={{
        position: 'relative',
        overflow: 'hidden',
        height: ['auto'],
      }}
    >
      <CarouselProvider
        naturalSlideWidth={1920}
        naturalSlideHeight={1280}
        totalSlides={images && images.length}
        infinite
        isPlaying
        style={{ position: 'relative' }}
      >
        <Slider>
          {images.map((image, i) => {
            if (!image.localFile) {
              return null
            }

            return (
              <Slide>
                <Image
                  key={`image-${i}`}
                  fadeIn={false}
                  loading="eager"
                  fluid={image.localFile.childImageSharp.fluid}
                  style={{
                    backgroundPosition: '50% 10%',
                    backgroundColor: '#333',
                    backgroundSize: 'cover',
                  }}
                />
              </Slide>
            )
          })}
        </Slider>
        <StyledButtonBack>
          <FormattedMessage id="carousel.previous" defaultMessage="Předchozí" />
        </StyledButtonBack>

        <StyledButtonNext>
          <FormattedMessage id="carousel.next" defaultMessage="Další" />
        </StyledButtonNext>
      </CarouselProvider>
    </Box>
  )
}

Carousel.propTypes = {
  images: PropTypes.array.isRequired,
}

export default Carousel
