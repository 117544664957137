import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledWrapper = styled.div`
  padding: 56.25% 0 0 0;
  position: relative;
`

const StyledIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const YouTubeGetID = (url) => {
  const urlParts = url.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/)
  return undefined !== urlParts[2]
    ? urlParts[2].split(/[^0-9a-z_-]/i)[0]
    : urlParts[0]
}

const VideoWrapper = ({ video }) => {
  const videoId = YouTubeGetID(video)

  return (
    <>
      <StyledWrapper>
        <StyledIframe
          src={`https://www.youtube-nocookie.com/embed/${videoId}`}
          frameBorder="0"
          allow="autoplay; encrypted-media, fullscreen"
          allowFullScreen
          title="video"
        />
      </StyledWrapper>
    </>
  )
}

VideoWrapper.propTypes = {
  video: PropTypes.string.isRequired,
}

export default VideoWrapper
